<template>
  <v-row  class="ml-1 pa-6">
    <v-col cols="12">
      <v-row >
        <v-col cols="12">
          <h2 class="font-weight-bold headline hidden-sm-and-down">정산받을 계좌 및<br>회원의 결제를 관리할 수 있습니다.</h2>
        </v-col>
        <v-col cols="12">
          <div class="body-1 font-weight-bold mt-6 mb-2">정산대금 입금계좌</div>
          <v-data-table :headers="[{ text: '예금주명', width: '80px', sortable: false},
                          { text: '은행명', width: '80px', sortable: false},
                          { text: '계좌번호', width: '120px', sortable: false},
                          { text: '상태', width: '96px', sortable: false},
                          { text: '등록/변경', width: '96px', sortable: false}]"
                        :items="accounts" hide-default-footer mobile-breakpoint="0"
                        style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12)">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.bankName }}</td>
                <td :class="props.item.name === '-' ? 'rouge--text' : ''"><v-icon color="rouge" v-if="props.item.name === '-'">error_outline</v-icon> {{ props.item.accountNumber }}</td>
                <td>{{ props.item.status || '활성' }}</td>
                <td class="text-right pa-0">
                  <v-btn v-if="props.item.name === '-'" class="rouge white-two--text ma-0 ml-6 mr-2" flat @click="showAddAccount=true"
                         :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                    <i class="fas fa-plus"></i>
                    <span class="ml-2 hidden-sm-and-down">추가</span>
                  </v-btn>
                  <v-btn v-else class="rouge white-two--text ma-0 ml-6 mr-2" flat @click="changeAccount(props.item)"
                         :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                    <i class="edit"></i>
                    <span class="ml-2">변경</span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">정산 내역</div>
            <v-row >
              <v-col cols="12" md="6">
                <v-btn-toggle v-model="duration" mandatory>
                  <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(0, 0)">
                    전체
                  </v-btn>
                  <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(1, 0)">
                    1개월
                  </v-btn>
                  <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(3, 0)">
                    3개월
                  </v-btn>
                  <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(6, 0)">
                    6개월
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-menu ref="menuStart" :close-on-content-click="false" v-model="menuStart" :nudge-right="40"
                          lazy transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" v-model="startFormatted" prepend-icon="event"
                                    @blur="startDate = parseDate(startFormatted)" style="max-width: 140px"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" locale="ko-kr" :max="endDate" no-title scrollable @input="menuStart = false">
                    </v-date-picker>
                  </v-menu>
                  <div class="pa-4 pt-6">-</div>
                  <v-menu :close-on-content-click="false" v-model="menuEnd" :nudge-right="40"
                          lazy transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" v-model="endFormatted" prepend-icon="event"
                                    @blur="endDate = parseDate(endFormatted)" style="max-width: 140px"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" locale="ko-kr" :min="startDate" no-title scrollable @input="menuEnd = false">
                    </v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
            <v-data-table :headers="[{ text: '정산예정', width: '120px', sortable: false},
                            // { text: '기간', width: '120px', sortable: false},
                            { text: '이용 건수', width: '80px', sortable: false},
                            { text: '정산 예정 금액', width: '104px', sortable: false},
                            { text: '승인 금액', width: '104px', sortable: false}]"
                          :items="filtered" mobile-breakpoint="0"
                          style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12);">
              <template v-slot:item="props">
                <tr style="font-size: 13px">
                  <td class="px-1">{{ $moment(props.item.date, 'YYYYMMDD').format('YYYY.MM.DD') }}</td>
<!--                  <td class="px-1">{{-->
<!--                    $moment(props.item.date, 'YYYYMMDD').subtract(34, 'd').add((7 - $moment(props.item.date, 'YYYYMMDD').weekday()) % 7, 'd').format('YYYY.MM.DD') + ' ~ ' +-->
<!--                    $moment(props.item.date, 'YYYYMMDD').subtract(28, 'd').add((7 - $moment(props.item.date, 'YYYYMMDD').weekday()) % 7, 'd').format('MM.DD')-->
<!--                    }}-->
<!--                  </td>-->
                  <td class="px-1 text-center">{{ props.item.list.length }}건</td>
                  <td class="px-1 text-center">{{ props.item.price | numFormat }}원</td>
                  <td class="px-1 text-center french-blue--text">{{ $moment(props.item.date, 'YYYYMMDD').isSameOrBefore(new Date()) ? (props.item.price || Math.round(props.item.price / 0.976)) + '원': '-'}}</td>
                </tr>
              </template>
              <template v-slot:footer>
                <td v-if="filtered.length > 0" :colspan="2" class="pa-1 text-left font-weight-bold">합계</td>
                <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">
                  {{_.sumBy(filtered, function(schedule) { return schedule.list.length; })}}건
                </td>
                <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">{{_.sumBy(filtered, 'price')}}원</td>
                <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">{{_.sumBy(filtered.map((schedule) => {
                    return $moment(schedule.date, 'YYYYMMDD').isSameOrBefore(new Date()) ? schedule : {price: 0}
                  }), 'price')}}원</td>
              </template>
            </v-data-table>
          </v-col>
      </v-row>
      <v-col class="text-right pa-0 ma-0" >
        <download-excel
          :data   = "filtered"
          :fields = "json_fields"
          name    = "settlement.xls">
          <v-btn color="#61c9a8" class="ma-0 white--text" style="min-width:150px">엑셀파일 다운로드</v-btn>
        </download-excel>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="showAddAccount" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showAddAccount=false">close</v-icon><br>
                <h2 class="mt-2 font-weight-bold headline">정산 계좌등록</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row >
                <v-col>
                  <div>정산대금이 입금될 계좌를 등록해주세요.<br>
                    등록된 계좌는 사업자 명의와 일치해야 합니다.</div>
                </v-col>
                <v-col cols="12" class="mt-6">
                  <v-row >
                    <v-col cols="8">
                      <div class="font-weight-bold mb-2">은행명</div>
                      <v-select v-model="account.bankNo"
                                :items="Object.entries(option.banks).map(([k, v]) => ({value: k, text: v})) || []"
                                item-text="text"
                                item-value="value"
                                class="pa-0"
                                hide-details
                                flat></v-select>
                    </v-col>
                  </v-row>
                  <v-row  class="mt-6">
                    <v-col cols="4" class="mr-4">
                      <div class="font-weight-bold mb-4">예금주명</div>
                      <v-text-field v-model="account.name" name="number"
                                    :rules="[errors.first('name') || !errors.has('name')]"
                                    return-masked-value
                                    hide-details
                                    single-line outlined solo flat></v-text-field>
                      <div class="font-weight-bold mt-6 mb-4">사업자 등록증 사본</div>
                      <div id="hidden-uploaded-files" style="display:none"></div>
                      <vue-dropzone ref="licenseDropzone" id="dropzone"
                                    :options="licenseDzOptions"
                                    @vdropzone-files-added="addedFile"
                                    @vdropzone-complete="completeFile"
                                    @vdropzone-success="successFile"
                                    @vdropzone-thumbnail="licenseThumbnailFile"
                                    :class="account.licenseFile ? 'rouge white-two--text ma-0': 'rouge--text ma-0'"
                                    :key="licenseKey">
                      </vue-dropzone>
                    </v-col>
                    <v-col cols="7">
                      <div class="font-weight-bold mb-4">계좌번호</div>
                      <v-text-field v-model="account.accountNumber" name="accountNumber"
                                    :rules="[errors.first('accountNumber') || !errors.has('accountNumber')]"
                                    return-masked-value
                                    hide-details
                                    single-line outlined solo flat></v-text-field>
                      <div class="font-weight-bold mt-6 mb-4">통장 사본</div>
                      <v-row>
                        <v-col cols="7">
                          <div id="hidden-uploaded-files1" style="display:none"></div>
                          <vue-dropzone ref="accountDropzone" id="dropzone1"
                                        :options="accountDzOptions"
                                        @vdropzone-files-added="addedFile"
                                        @vdropzone-complete="completeFile"
                                        @vdropzone-success="successFile"
                                        @vdropzone-thumbnail="accountThumbnailFile"
                                        :class="account.accountFile ? 'rouge white-two--text ma-0': 'rouge--text ma-0'"
                                        :key="accountKey">
                          </vue-dropzone>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="rouge" outlined flat @click.native="showAddAccount=false">취소</v-btn>
              <v-btn class="rouge white-two--text" flat @click="showAddAccount=false; addAccount()">계좌 등록</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import firebase from 'firebase'

  import companyMixin from '@/components/shared/companyMixin'
  import userMixin from "@/components/shared/userMixin";

  import JsonExcel from 'vue-json-excel'
  import Vue from 'vue'

  export default {
    name: 'Schedule',
    mixins: [userMixin, companyMixin],
    props: ['schedules', 'option'],
    components: {
      vueDropzone: () => import('@/components/shared/dropzone/vue-dropzone'),
      downloadExcel: JsonExcel
    },
    data() {
      return {
        accounts: [{
          name: '-',
          bankName: '-',
          bankNo: '',
          accountNumber: '정산받으실 계좌를 등록해주세요.',
          status: '-'
        }],
        account: {
          status: '확인중'
        },
        showAddAccount: false,
        licenseKey: 0,
        accountKey: 0,
        licenseDzOptions: {
          url: process.env.VUE_APP_API_URL + '/utils/upload',
          paramName: 'file',
          acceptedFiles: 'image/*',
          previewsContainer: '#hidden-uploaded-files',
          dictDefaultMessage: '<i class="material-icons">edit</i><span class="hidden-sm-and-down">&nbsp;&nbsp;&nbsp;파일 업로드</span>',
          resizeWidth: 1980,
          resizeHeight: 1980,
          resizeMethod: 'contain',
          createImageThumbnails: true,
          thumbnailWidth: 800,
          thumbnailHeight: 800,
          thumbnailMethod: 'contain'
        },
        accountDzOptions: {
          url: process.env.VUE_APP_API_URL + '/utils/upload',
          paramName: 'file',
          acceptedFiles: 'image/*',
          previewsContainer: '#hidden-uploaded-files',
          dictDefaultMessage: '<i class="material-icons">edit</i><span class="hidden-sm-and-down">&nbsp;&nbsp;&nbsp;파일 업로드</span>',
          resizeWidth: 1980,
          resizeHeight: 1980,
          resizeMethod: 'contain',
          createImageThumbnails: true,
          thumbnailWidth: 800,
          thumbnailHeight: 800,
          thumbnailMethod: 'contain'
        },
        // settlementDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        duration: 1,
        menuStart: false,
        menuEnd: false,
        startDate: '',
        endDate: '',
        startFormatted: null,
        endFormatted: null,
        json_fields: {
          '정산예정일': {
            callback: (value) => {
              return Vue.moment(value.date).tz('Asia/Seoul').format('YYYY-MM-DD HH:MM');
            }
          },
          '기간': {
            callback: (value) => {
              return Vue.moment(value.date).tz('Asia/Seoul').subtract(34, 'd').add((7 - Vue.moment(value.date, 'YYYYMMDD').weekday()) % 7, 'd').format('YYYY.MM.DD') + ' ~ ' +
                Vue.moment(value.date).tz('Asia/Seoul').subtract(28, 'd').add((7 - Vue.moment(value.date, 'YYYYMMDD').weekday()) % 7, 'd').format('MM.DD')
            }
          },
          '이용건수': {
            callback: (value) => {
              return value.list.length;
            }
          },
          '정산예정금': 'price',
          '승인금액': {
            callback: (value) => {
              return (Vue.moment(value.date, 'YYYYMMDD').isSameOrBefore(new Date()) ? value.price : '-');
            }
          }
        }
      }
    },
    computed: {
      filtered: function () {
        return (this.schedules || []).filter((schedule) => {
          let date = this.$moment(schedule.date, 'YYYYMMDD').tz('Asia/Seoul').format('YYYY-MM-DD');
          return this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]');
        }) || [];
      }
    },
    watch: {
      startDate () {
        this.startFormatted = this.formatDate(this.startDate)
      },
      endDate () {
        this.endFormatted = this.formatDate(this.endDate)
      },
      schedules(value) {
        this.getInitData(value)
      }
    },
    methods: {
      async addAccount() {
        try {
          let result = null;
          if (this.account.id) {
            result = await this.axios.put('/company/user/account?userId=' + this.user.id, this.account);
          }
          else {
            result = await this.axios.post('/company/user/account?userId=' + this.user.id, this.account);
          }
          if (result.data.message) {
            this.$store.commit('setMsg', result.data.message);
            this.$store.commit('setShowSnackbar', true);
          }

          let accounts = await this.axios.get('/company/user/account?userId=' + this.user.id);
          this.accounts = accounts.data.accounts;
        }
        catch (e) {
          this.$store.commit('setMsg', '계좌 정보를 확인해 주세요.');
          this.$store.commit('setShowSnackbar', true);
          console.log(e);
        }
      },
      async changeAccount(item) {
        this.showAddAccount=true;
        this.account = item;
      },
      addedFile () {
        this.isFade = false
      },
      completeFile () {
        this.isFade = true
      },
      successFile () {
      },
      async licenseThumbnailFile (file, dataUrl) {
        let extension = file.name.split('.');
        let filename = file.upload.uuid.replace(/[^A-Fa-f0-9]/g, '') + '.' + extension[extension.length - 1];

        if (file.dataURL.indexOf('base64') > -1) {
          let thumbRef = firebase.storage().ref('companyuser/' + this.user.id).child('thumb_' + filename);
          await thumbRef.putString(dataUrl.split('base64,')[1], 'base64', {contentType: file.type});
          let url = await thumbRef.getDownloadURL();
          this.account.licenseFile = url;
          this.licenseDzOptions.dictDefaultMessage = '<i class="material-icons">done</i><span class="hidden-sm-and-down">&nbsp;&nbsp;&nbsp;파일 업로드</span>';
          this.licenseKey++;
        }
      },
      async accountThumbnailFile (file, dataUrl) {
        let extension = file.name.split('.');
        let filename = file.upload.uuid.replace(/[^A-Fa-f0-9]/g, '') + '.' + extension[extension.length - 1];

        if (file.dataURL.indexOf('base64') > -1) {
          let thumbRef = firebase.storage().ref('companyuser/' + this.user.id).child('thumb_' + filename);
          await thumbRef.putString(dataUrl.split('base64,')[1], 'base64', {contentType: file.type});
          let url = await thumbRef.getDownloadURL();
          this.account.accountFile = url;
          this.accountDzOptions.dictDefaultMessage = '<i class="material-icons">done</i><span class="hidden-sm-and-down">&nbsp;&nbsp;&nbsp;파일 업로드</span>';
          this.accountKey++;
        }
      },
      formatDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${year}. ${month}. ${day}`
      },
      parseDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('. ');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      updateDate (mon, week) {
        this.endDate = this.$moment.tz('Asia/Seoul').add(22, 'd').add((7 - new Date().getDay()) % 7, 'd').add(6, 'd').format('YYYY-MM-DD');
        if(mon > 0 || week > 0) {
          this.startDate = this.$moment(this.endDate).subtract(mon, 'months').subtract(week, 'weeks').format('YYYY-MM-DD');
        }
        else {
          this.startDate = this.$moment().subtract(10, 'years').format('YYYY-MM-DD')
        }
      },
      async getInitData(schedules) {
        this.schedules = schedules;
        // this.filtered = settlements.filter((settlement) => {
        //   return(settlement.status == 5)
        // });
        // this.filtered = this._.orderBy(this.filtered, ['cid', 'paidAt'], ['desc', 'desc']);
      }
    },
    async created() {
      this.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      this.endDate = this.$moment.tz('Asia/Seoul').add(22, 'd').add((7 - new Date().getDay()) % 7, 'd').add(6, 'd').format('YYYY-MM-DD');

      let accounts = await this.axios.get('/company/user/account?userId=' + this.user.id);
      if (accounts.data.total > 0) {
        this.accounts = accounts.data.accounts;
        this.accounts = this.accounts.map((account) => {
          if (!account.bankNo && account.bankName) {
            account.bankNo = this._.invert(this.option['banks'])[account.bankName];
          }
          return account;
        });
      }

      this.getInitData(this.schedules);

      this.startFormatted = this.formatDate(this.startDate);
      this.endFormatted = this.formatDate(this.endDate);

      this.updateDate(1, 0);
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-text-field--outline.v-text-field--single-line input {
    margin-top: 0;
  }

  ::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
    min-height: 40px;
    border-radius: 4px;
    border: solid 1px #c2c2c2;
    border: solid 1px var(--pinkish-grey-color);
  }

  @media only screen and (max-width: 959px) {
    ::v-deep .vue-dropzone.dropzone {
      min-width: 40px;
      min-height: 36px;
      padding: 7px 0px;
      border-color: var(--rouge-color);
    }
  }

  @media only screen and (min-width: 960px) {
    ::v-deep .vue-dropzone.dropzone {
      min-width: 88px;
      min-height: 36px;
      padding: 7px 0px;
      border-color: var(--rouge-color);
    }
  }

  ::v-deep .dz-default.dz-message {
    margin: 0
  }
</style>